import { createRouter, createWebHistory } from 'vue-router'
import AppVue from './App.vue'

const routes = [
    {
        path: '/',
        component: AppVue,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    // If the route doesn't exist, redirect to the homepage
    if (!to.matched.length) {
        next('/')
    } else {
        next()
    }
})

export default router
