import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Viewer } from '@photo-sphere-viewer/core'
import { GyroscopePlugin } from '@photo-sphere-viewer/gyroscope-plugin'
import { VisibleRangePlugin } from '@photo-sphere-viewer/visible-range-plugin'
import { getOperatingSystem } from './scripts/device'

const app = createApp(App)
app.use(router)
app.mount('#app')
getOperatingSystem()

const queryParams = new URLSearchParams(window.location.search)
let productParam = queryParams.get('product') || 'easycare'
let panorama_url = `${window.location.origin}/assets/360-${productParam}.jpg`

const viewer = new Viewer({
    container: document.querySelector('#viewer'),
    panorama: panorama_url,
    defaultZoomLvl: 1,
    minFov: 65 /* increase to limit zoom level */,
    maxFov: 95,
    defaultYaw: 0,
    mousewheel: true,
    navbar: ['gyroscope', 'caption'],
    plugins: [
        [GyroscopePlugin],
        [
            VisibleRangePlugin,
            {
                verticalRange: [-Math.PI / 2, Math.PI / 2.5],
            },
        ],
    ],
})

/*  Automatically set Gyroscope Plugin after overlay click. 'touchstart' on Android does not require delay check. But it does for ios. */
window.addEventListener('load', function () {
    const overlay = document.querySelector('.psv-container')

    if (/Android/i.test(navigator.userAgent)) {
        overlay.addEventListener('touchstart', function () {
            document.getElementsByClassName('psv-gyroscope-button')[0].click()
        })
    } else {
        let touchStartX = 0
        let touchStartY = 0
        overlay.addEventListener('touchstart', function (e) {
            touchStartX = e.touches[0].clientX
            touchStartY = e.touches[0].clientY
        })
        overlay.addEventListener('touchend', function (e) {
            const touchEndX = e.changedTouches[0].clientX
            const touchEndY = e.changedTouches[0].clientY
            const xDiff = touchEndX - touchStartX
            const yDiff = touchEndY - touchStartY
            if (Math.abs(xDiff) < 5 && Math.abs(yDiff) < 5) {
                document.getElementsByClassName('psv-gyroscope-button')[0].click()
            }
        })
    }
})

/* Show Overlay */
function showOverlay() {
    viewer.overlay.show({
        image: document.getElementById('overlay-logo').innerHTML,
        title: document.getElementById('overlay-title').innerHTML,
        text: document.getElementById('overlay-icons').innerHTML,
        dissmisable: true,
    })
}
viewer.addEventListener('ready', showOverlay, { once: true })

/* Custom events to change panorama background depending on which seed is clicked/loaded. */
let seedArray = document.getElementsByClassName('seed')
let defaultSeed = seedArray[0]
let product = queryParams.get('product')

if (!product) {
    product = 'easycare'
}

if (product) {
    for (let i = 0; i < seedArray.length; i++) {
        let seed = seedArray[i]
        let seedProduct = seed.getAttribute('data-seed')
        if (seedProduct === product) {
            defaultSeed = seed
            break
        }
    }
    defaultSeed.classList.add('active')
}

function setPanoramaAndHideLoader(panoramaUrl, product) {
    viewer.setPanorama(panoramaUrl)
    document.getElementsByClassName('psv-loader-container')[0].style.display = 'none'
    history.pushState({}, '', `?product=${product}`)
}

for (let i = 0; i < seedArray.length; i++) {
    let seed = seedArray[i]
    let product = seed.getAttribute('data-seed')
    seed.addEventListener(
        'click',
        function () {
            setPanoramaAndHideLoader(`assets/360-${product}.jpg`, product)
            // Remove the "active" class from all seeds
            for (let j = 0; j < seedArray.length; j++) {
                seedArray[j].classList.remove('active')
            }
            // Add the "active" class to the clicked seed
            seed.classList.add('active')
        },
        false
    )
}

/* Reposition navbar if the selected nav item is off the screen. */
const navbar = document.getElementsByClassName('menu')[0]
const selectedItem = document.getElementsByClassName('active')[0]

// Get the position of the selected item relative to the navbar
const selectedItemRect = selectedItem.getBoundingClientRect()
const navbarRect = navbar.getBoundingClientRect()
const selectedItemOffset = selectedItemRect.left - navbarRect.left

// Check if the selected item is off-screen
if (selectedItemOffset < 0 || selectedItemOffset > navbarRect.width) {
    // Calculate the new scroll position to bring the selected item into view
    const newScrollLeft = navbar.scrollLeft + selectedItemOffset

    // Animate the navbar scroll to the new position
    navbar.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
    })
}
