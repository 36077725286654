<template>
    <loading :active="isLoading" :is-full-page="true" :can-cancel="false" :loader="loader" :color="'#83c446'"></loading>
    <Overlays></Overlays>
    <div id="viewer"></div>
    <Menus></Menus>
</template>

<script>
import Overlays from './components/Overlays.vue'
import Menus from './components/Menus.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

export default {
    name: 'App',
    components: {
        Overlays,
        Menus,
        Loading,
    },
    data() {
        return {
            isLoading: true,
            loader: 'dots',
        }
    },
    mounted() {
        // Hide the loading overlay after everything has loaded
        window.onload = () => {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    },
}
</script>

<style>


@import '@photo-sphere-viewer/core/index.css';
@import '@/styles/main.scss';
@import '@/styles/responsive.scss';
@import '@/styles/navbar.scss';

@font-face {font-family: "Solido"; 
            src: url("../public/assets/fonts/solido.eot"); 
            src: url("../public/assets/fonts/solido.eot?#iefix") format("embedded-opentype"), 
            url("../public/assets/fonts/solido.woff2") format("woff2"), 
            url("../public/assets/fonts/solido.woff") format("woff"), 
            url("../public/assets/fonts/solido.ttf") format("truetype"), 
            url("../public/assets/fonts/solido.svg#Solido") format("svg"); }

@font-face {
    font-family: 'CalibreWeb';
    src: url('../public/assets/fonts/calibreweb.eot');
    src: url('../public/assets/fonts/calibreweb.eot?#iefix') format('embedded-opentype'),
        url('../public/assets/fonts/calibreweb.woff2') format('woff2'),
        url('../public/assets/fonts/calibreweb.woff') format('woff'),
        url('../public/assets/fonts/calibreweb.ttf') format('truetype'),
        url('../public/assets/fonts/calibreweb.svg#Copyright Klim Type Foundry') format('svg');
}

canvas {
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
}
</style>
