/**
 * Determine the operating system.
 */

export function getOperatingSystem() {
    if (window?.navigator?.userAgent.includes('SamsungBrowser')) {
        const el = document.getElementById('viewer')
        el.style.height = '100vh'
    }
}
