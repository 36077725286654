<template>
    <div id="overlay-logo">
        <div class="banner">
            <img src="assets/logos/lawn-force-vr-logo.png" alt="tui-logo-transparent" />
        </div>
    </div>

    <div id="overlay-title">
        <h1>CHOOSE YOUR PERFECT LAWN SEED</h1>
        <h4>TUI LAWNFORCE VR EXPERIENCE</h4>
        <div class="dots"></div>
    </div>

    <div id="overlay-icons">
        <div v-if="!isMobile" class="icon-container desktop">
            <div class="item pan">
                <img class="icon" src="assets/icons/desktop-pan.svg" alt="pan" />
                <p>PAN</p>
            </div>
            <div class="item zoom">
                <img class="icon" src="assets/icons/desktop-zoom.svg" alt="zoom" />
                <p>ZOOM</p>
            </div>
            <div class="item interact">
                <img class="icon" src="assets/icons/desktop-click.svg" alt="interact" />
                <p>INTERACT</p>
            </div>
        </div>

        <div v-else class="icon-container mobile">
            <div class="item pan">
                <img class="icon" src="assets/icons/pan.svg" alt="pan" />
                <p>PAN</p>
            </div>
            <div class="item zoom">
                <img class="icon" src="assets/icons/zoom.svg" alt="zoom" />
                <p>ZOOM</p>
            </div>
            <div class="item interact">
                <img class="icon" src="assets/icons/interact.svg" alt="interact" />
                <p>INTERACT</p>
            </div>
            <div class="item vr">
                <img class="icon" src="assets/icons/vr.svg" alt="vr" />
                <p>VR MODE</p>
            </div>
        </div>
        <div>
            <button v-if="!isMobile" class="click">CLICK TO ENTER</button>
            <button v-else class="tap">TAP TO ENTER</button>

            <div class="desktop-warning">
                To get the best experience, we recommend viewing this site on a mobile device.<br />
                If your screen height is smaller than 780px, some items may display incorrectly on the screen.
            </div>
        </div>

        <div v-if="!isMobile" class="bottom-panel">&#8203;</div>
    </div>

    <div id="rotate-device" class="dots">
        <div class="grid-container">
            <img src="assets/icons/rotate.svg" alt="rotate" />
            <p>WE CAN'T FIT EVERYTHING ON YOUR SCREEN. PLEASE ROTATE YOUR DEVICE.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overlays',
    computed: {
        isMobile() {
            return 'ontouchstart' in window
        },
    },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

/*
OVERLAY STYLING OVERRIDES
*/

/* This is to avoid the div from appearing in the DOM */
#overlay-logo,
#overlay-title,
#overlay-icons,
#rotate-device {
    display: none;

    &.dots {
        top: unset !important;
    }

    img {
        width: 100px;
    }

    p {
        font-family: 'Solido';
        font-size: 1.5rem;
        color: #000;
        text-align: center;
    }
}

.dots {
    position: absolute;
    width: 100vw;
    height: 50vh;
    top: 220px;
    background: url('../../public/assets/branding/bg-dots-light.png');
    background-repeat: repeat-x !important;
    background-position-y: -10px;
    overflow: hidden;
    z-index: -50;
    pointer-events: none;
}

/* Hide the default loading icon */
.psv-loader {
    display: none !important;
}

img {
    display: block;
    margin: 0 auto;
}

.psv-overlay {
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: unset !important;
    @media screen and (max-width: 320px) {
        justify-content: unset !important;
    }

    &-image {
        background: linear-gradient(180deg, #1f3b33, #3c5149) !important;
        margin-top: 4vh;
        .banner {
            width: 100vw;
            background-repeat: repeat-x !important;
            background-size: 20px 200px !important;
            background-image: url('../../public/assets/branding/bg-dots-dark.png') !important;

            @media screen and (max-width: 320px) {
                background-size: 15px 120px !important;
            }
        }

        @media screen and (max-width: 320px) {
            height: 120px;
        }

        img {
            @media screen and (max-width: 320px) {
                width: 120px;
            }

            @media screen and (max-height: 580px) {
                width: 150px;
            }
        }
    }

    &-text {
        white-space: pre-line;
        height: 100%;
        width: 100%;

        .icon-container {
            display: grid;
            grid-template-columns: repeat(4, max-content);
            justify-content: center;

            &.desktop {
                grid-template-columns: repeat(3, max-content);
                grid-gap: 50px;
            }

            &.mobile {
                grid-template-columns: repeat(4, max-content);
                grid-gap: 20px;
            }

            .item {
                display: grid;
            }

            .icon {
                width: 60px;
                @media screen and (max-width: 320px) {
                    width: 50px;
                }
            }
        }

        p {
            margin-top: 0;
            color: #000000;
            font-family: 'Solido';
            @media screen and (max-width: 320px) {
                font-size: 1.1rem;
            }
        }

        .click,
        .tap {
            font-family: 'Solido';
            border: 2px solid #000;
            color: #000;
            font-size: 1.1rem;
            background: transparent;
            cursor: pointer;
            @media screen and (max-width: 320px) {
                font-size: 0.9rem;
            }
        }

        .desktop-warning {
            display: none;
            font-size: 16px;
            line-height: 20px;
            padding-top: 30px;

            @media screen and (orientation: landscape) {
                display: block;
            }

            @media screen and (max-height: 780px) and (min-width: 500px) {
                display: block;
            }
        }
    }

    &-title {
        width: 100%;
        h1 {
            color: #83c446;
            font-size: 3rem;
            @media screen and (max-width: 481px) {
                padding: 0 80px;
                font-size: 1.6rem;
                margin-bottom: 15px;
            }
            @media screen and (max-width: 380px) {
                padding: 0 100px;
                font-size: 1.4rem;
                margin-bottom: 15px;
            }
            @media screen and (max-width: 320px) {
                padding: 0 50px;
            }
            @media screen and (max-height: 813px) {
                margin-top: 0px;
            }
        }
        h4 {
            @media screen and (max-width: 481px) {
                font-size: 1.4rem;
            }
            @media screen and (max-width: 380px) {
                font-size: 1.2rem;
                margin-top: 0;
            }
        }
    }
}

.bottom-panel {
    width: 100%;
    height: 100%;
    background-color: #518d19;
    margin-top: 5.5rem;
}

.vl-background {
    opacity: 1 !important;
}
</style>
