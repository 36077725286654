<template>
    <div class="container">
        <div class="floating-menu">
            <a href="https://tuigarden.co.nz/lawn-force-hub/" target="”_blank”"><img class="shield" src="/assets/logos/shield.png" alt="shield.png" /></a>
        </div>
        <img v-if="isMobile" class="icon compass" src="assets/icons/compass.svg" alt="compass" title="Gyroscope" />
        <div class="pop-up-container" :class="{ open: showCard }">
            <img v-if="!showCard" v-bind:onclick="toggleCard" class="icon information" src="assets/icons/information.svg" alt="information" />
            <img v-bind:onclick="toggleCard" class="icon close" src="assets/icons/close.svg" alt="close" />

            <img class="seed-sample" v-bind:src="seeds[seedIndex].sample" />

            <div class="pop-up-content" :class="{ 'pop-up-content-bottom': hideBenefits}">
                <img v-bind:onclick="toggleBenefits" class="icon minus" src="assets/icons/minus.svg" alt="close" v-if="!hideBenefits"/>
                <img v-bind:onclick="toggleBenefits" class="icon close-rotated" src="assets/icons/close.svg" alt="close" v-if="hideBenefits"/>
                <h2 class="title" :class="'title-' + [seeds[seedIndex].selector]">{{ seeds[seedIndex].card.title }}</h2>
                <ul class="ul-details" :class="{ 'hide-benefits': hideBenefits}">
                    <li v-for="item in seeds[seedIndex].card.items">
                        <p>{{ item }}</p>
                    </li>
                </ul>
                <button class="btn-navigation" :class="'btn-' + seeds[seedIndex].selector" @click="navigateToProduct">Learn more here ></button>
            </div>
        </div>

        <div class="nav-menu-container">
            <div class="ui">
                <div class="menu">
                    <div class="container">
                        <div
                            class="seed"
                            v-for="(item, index) in seeds"
                            :data-seed="item.selector"
                            :data-selected="index === seedIndex"
                            :key="index"
                            @click="event => selectMenuItem(index)"
                        >
                            <div class="image">
                                <img class="product-image" v-bind:src="item.packaging" />
                            </div>
                            <h6 class="product">{{ item.name }}</h6>
                            <div class="navbar-selector" :class="[`nav-${item.selector}`]">&#8203;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const grassAsset = 'assets/grass-samples/'

window.addEventListener('load', function () {
    const compass = document.querySelector('.compass')
    if(compass !== null){
        compass.addEventListener('touchstart', function () {
            document.getElementsByClassName('psv-gyroscope-button')[0].click()
            console.log('gyro clicked')
        })
    }
})

export default {
    name: 'SeedMenu',
    props: {
        msg: String,
    },
    data() {
        return {
            showCard: true,
            seedIndex: 0,
            hideBenefits: false,
            seeds: [
                {
                    name: 'EASYCARE',
                    packaging: 'assets/packaging/easycare.png',
                    sample: `${grassAsset}easycare.jpg`,
                    selector: 'easycare',

                    card: {
                        title: 'SUPERSTRIKE® EASYCARE LAWN SEED',
                        size: '750g & 2.5kg',
                        items: [
                            'Low maintenance lawn for less mowing.',
                            'Blend of quality ryegrass and turf fescue.',
                            'Medium blade.',
                            'Light green.',
                            'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                            'Pest guard - protected from seedling diseases and bird theft.'
                        ]
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-easycare-lawn-seed/`,
                },
                {
                    name: 'HOT & DRY',
                    packaging: 'assets/packaging/hot-and-dry.png',
                    sample: `${grassAsset}hot-and-dry.jpg`,
                    selector: 'hot-and-dry',

                    card: {
                        title: 'SUPERSTRIKE® HOT & DRY LAWN SEED',
                        size: '750g',
                        items: [
                            'Drought tolerant lawn for dry conditions or sandy soil.',
                            'Blend includes  tall fescue, fine turf ryegrass and Kentucky bluegrass.',
                            'Medium blade.',
                            'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                            'Pest guard - protected from seedling diseases and bird theft.'
                        ]
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-hot-dry-lawn-seed/`,
                },
                {
                    name: 'TALL FESCUE',
                    packaging: 'assets/packaging/tall-fescue.png',
                    sample: `${grassAsset}tall-fescue.jpg`,
                    selector: 'tall-fescue',
                    card: {
                        title: 'SUPERSTRIKE® TALL FESCUE LAWN SEED',
                        size: '750g',
                        items: [
                            'Suited to landscape and ornamental lawns.',
                            'Tall fescue blend.',
                            'Thick blade & drought tolerant.',
                            'Dense dark green colour.',
                            'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                            'Pest guard - protected from seedling diseases and bird theft.'
                        ]    
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-tall-fescue-lawn-seed/`,
                },
                {
                    name: 'ALL SEASONS',
                    packaging: 'assets/packaging/all-seasons.png',
                    sample: `${grassAsset}all-seasons.jpg`,
                    selector: 'all-seasons',
                    card: {
                        title: 'SUPERSTRIKE® ALL SEASONS LAWN SEED',
                        size: '750g',
                        items: [
                            'Winter active to grow a lawn year-round.',
                            'Blend includes two varieties of fine turf ryegrass.',
                            'Medium blade.',
                            'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                            'Pest guard - protected from seedling diseases and bird theft.'
                        ]
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-all-seasons-lawn-seed/`,
                },
                {
                    name: 'HARDWEARING',
                    packaging: 'assets/packaging/hardwearing.png',
                    sample: `${grassAsset}hardwearing.jpg`,
                    selector: 'hardwearing',

                    card: {
                        title: 'SUPERSTRIKE® HARDWEARING LAWN SEED',
                        size: '750g & 2.5kg',
                        items: [
                            'Hardwearing lawn to withstand everyday wear and tear.',
                            'Blend of perennial ryegrass and fine turf fescue.',
                            'Thick blade.',
                            'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                            'Pest guard - protected from seedling diseases and bird theft.'
                        ]
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-hardwearing-lawn-seed/`,
                },

                {
                    name: 'SUPERFINE',
                    packaging: 'assets/packaging/superfine.png',
                    sample: `${grassAsset}superfine.jpg`,
                    selector: 'superfine',

                    card: {
                        title: 'SUPERSTRIKE® SUPERFINE LAWN SEED',
                        size: '750g',
                        items: [
                            'Slow growing ornamental lawn for less mowing.',
                            'Blend includes turf fescue and browntop.',
                            'Fine textured blade.',
                            'Light green.',
                            'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                            'Pest guard - protected from seedling diseases and bird theft.'
                        ]
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-superfine-lawn-seed/`,
                },
                {
                    name: 'SHADY PLACES',
                    packaging: 'assets/packaging/shady-places.png',
                    sample: `${grassAsset}shady-places.jpg`,
                    selector: 'shady-places',
                    card: {
                        title: 'SUPERSTRIKE® SHADY PLACES LAWN SEED',
                        size: '750g',
                        items: [
                           'Slow growing lawn for less mowing in shady spots.',
                           'Blend of tall fescue and fine turf fescues that thrive in less sunlight.',
                           'Medium-fine blade.',
                           'Deep green.',
                           'Quick grow - establish a lawn in as little as 7 days under ideal conditions.',
                           'Pest guard - protected from seedling diseases and bird theft.'
                        ]
                    },
                    link: `https://tuigarden.co.nz/product/tui-lawnforce-superstrike-shady-places-lawn-seed/`,
                },
            ],
        }
    },
    mounted() {
        // get the value of the "product" parameter from the URL
        const product = this.getUrlParam('product')

        // find the seed object that matches the product selector
        const seed = this.seeds.find(s => s.selector === product)

        // set the seedIndex to the index of the matching seed object
        if (seed) {
            this.seedIndex = this.seeds.indexOf(seed)
        }
    },
    methods: {
        selectMenuItem(index) {
            if (index < 0) index = 0
            if (index > this.seeds.length - 1) index = this.seeds.length - 1

            // Select menu item
            this.seedIndex = index
            this.hideBenefits = false
        },
        toggleCard() {
            this.showCard = !this.showCard
            this.hideBenefits = false
        },
        toggleBenefits() {
            this.hideBenefits = !this.hideBenefits
        },
        navigateToProduct() {
            window.open(this.seeds[this.seedIndex].link, '_blank')
        },
        getUrlParam() {
            const urlParams = new URLSearchParams(window.location.search)
            return urlParams.get('product')
        },
    },
    computed: {
        isMobile() {
            return window.matchMedia('(max-width: 768px)').matches && 'ontouchstart' in window
        },
        isPopupOpen() {
            return this.showCard
        },
    },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.container {
    .floating-menu {
        position: absolute;
        display: grid;
        grid-gap: 10px;
        align-items: center;
        top: 36px;
        left: 36px;
        z-index: 2;

        @media screen and (max-width: 625px) {
            top: 2%;
            left: 3%;
        }

        .shield {
            @media screen and (max-width: 625px) {
                width: 100px;
            }
            @media screen and (max-width: 380px) {
                width: 90px;
            }
            @media screen and (max-width: 320px) {
                width: 70px;
            }
        }

        .icon {
            width: 40px;
            cursor: pointer;
        }
    }

    .compass {
        position: absolute;
        right: auto;
        left: 180px;
        top: 70px;
        width: 35px;
        cursor: pointer;
    
        @media screen and (max-width: 625px) {
            top: 24px;
            left: 0;
            right: 0;
            margin: auto;
        }

        z-index: 100;
    }

    // pop-up-container menu styling
    .pop-up-container {
        height: 350px;
        &.open {
            visibility: visible;
        }

        visibility: hidden;
        position: fixed;
        top: 6%;
        right: 5%;
        width: 350px;
        max-width: calc(100vw - 20px);

        @media screen and (max-width: 625px) {
            top: 216px;
            right: 50%;
            transform: translate(50%, -50%);
        }
        @media screen and (max-width: 390px) {
            width: 330px;
        }
        @media screen and (max-width: 380px) and (max-height: 670px) {
            right: 50%;
            transform: translate(50%, -50%);
            width: 290px;
        }
        @media screen and (max-width: 320px) {
            width: 260px;
        }

        .seed-sample {
            background: #e6e6e6;
            padding: 3px;
            border-radius: 10px;
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 10px;
            overflow: hidden;
            @media screen and (max-width: 481px) {
                height: 240px;
            }
            @media screen and (max-width: 380px) {
                height: 190px;
            }
            @media screen and (max-width: 320px) {
                height: 160px;
            }
        }

        .pop-up-content-bottom {
            margin-top: 100px !important;

            @media screen and (max-width: 381px) {
                margin-top: 40px !important;
            }

            @media screen and (max-width: 381px) and (max-height: 667px) {
                margin-top: 100px !important;
            }

            @media screen and (max-width: 320px) {
                margin-top: 65px !important;
            }
        }

        .pop-up-content {
            margin: 10px 0 10px 40px;
            padding: 20px;
            background: #fbfbfb;
            border-radius: 5px;
            transform: translate(-5px, -120px);
            color: black;
            position: relative;

            .minus,
            .close-rotated {
                color: #fff;
                cursor: pointer;
                background: #333;
                font-size: 14px;
                border-radius: 50%;
                display: block;
                height: 20px;
                position: absolute;
                right: 3px;
                top: 3px;
                width: 20px;
            }

            .close-rotated {
                rotate: 45deg;
            }


            @media screen and (max-width: 481px) {
                padding: 10px 20px 20px 20px;
            }

            @media screen and (max-width: 380px) {
                padding: 10px 20px 30px 20px;
                font-size: 1rem;

                @media screen and (min-height: 667px) and (max-height: 813px) {
                    transform: translate(-5px, -50px);
                }
            }

            @media screen and (max-width: 320px) {
                transform: translateY(-80px);
                margin: 10px 0 10px 20px;
            }

            .title {
                font-size: 1.4rem;
                max-width: 95%;

                @media screen and (max-width: 380px) {
                    font-size: 1.1rem;
                }

                @media screen and (max-width: 320px) {
                    font-size: 1rem;
                }
            }

            .hide-benefits {
                height: 0;
                overflow: hidden;
            }

            .ul-details {
                margin: 0;
                padding: 0 15px;

                @media screen and (max-height: 675px) and (max-width: 481px) {
                    padding: 0 5px;
                }
                
                li {  
                    padding: 0;
                    p {
                        font-family: 'CalibreWeb';
                        padding: 0;
                        margin: 0;

                        @media screen and (max-height: 675px) and (max-width: 481px) {
                            font-size: 0.9rem;
                            line-height: 1rem;
                        }

                        @media screen and (max-width: 380px) {
                            line-height: 1em;
                            font-size: 0.9rem;
                        }

                        @media screen and (max-width: 320px) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }

            .btn-navigation {
                position: absolute;
                padding: 10px;
                transform: translate(150px, 0px);
                border-radius: 5px;
                color: #fff;
                border: 1px solid #fff;
                cursor: pointer;

                @media screen and (max-width: 390px) {
                    transform: translate(130px, 5px);
                }
                @media screen and (max-width: 380px) {
                    padding: 8px;
                    font-size: 0.8rem;
                    transform: translate(85px, 10px);
                }
                @media screen and (max-width: 320px) {
                    padding: 7px;
                    font-size: 0.7rem;
                }

                &.btn-all-seasons {
                    background-color: $all-seasons;
                }
                &.btn-tall-fescue {
                    background-color: $tall-fescue;
                }
                &.btn-superfine {
                    background-color: $superfine;
                }
                &.btn-shady-places {
                    background-color: $shady-places;
                }
                &.btn-easycare {
                    background-color: $easycare;
                }
                &.btn-hot-and-dry {
                    background-color: $hot-and-dry;
                }
                &.btn-hardwearing {
                    background-color: $hardwearing;
                }
            }

            // Dynamic Title and Button Colours
            .title-all-seasons {
                color: $all-seasons;
            }

            .title-tall-fescue {
                color: $tall-fescue;
            }

            .title-superfine {
                color: $superfine;
            }

            .title-shady-places {
                color: $shady-places;
            }

            .title-easycare {
                color: $easycare;
            }

            .title-hot-and-dry {
                color: $hot-and-dry;
            }

            .title-hardwearing {
                color: $hardwearing;
            }
            // End of Dynamic Title and Button Colours

            p {
                color: black;
            }

            .title,
            .size,
            .details {
                margin: 0;
            }
        }

        .close,
        .information {
            position: absolute;
            right: -18px;
            top: -14px;
            width: 34px;
            cursor: pointer;
            @media screen and (max-width: 320px) {
                width: 30px;
            }
        }

        .information {
            visibility: visible !important;
        }
    }
    // End of pop-up-container menu styling

    .nav-menu-container {
        position: absolute;
        bottom: 0;
        z-index: 2;

        .ui {
            display: grid;

            .menu {
                position: relative;
                overflow: auto;
                overflow-y: hidden;

                .container {
                    display: flex;
                    min-width: calc(100vw - 31px);
                    width: max-content;
                    justify-content: center;
                    margin-top: 0;
                    margin-left: 15px;
                    margin-right: 15px;

                    .seed {
                        display: grid;
                        gap: 10px;
                        width: 150px;
                        cursor: pointer;
                        justify-items: center;

                        @media screen and (max-width: 380px) {
                            width: 130px;
                            gap: 5px;
                        }

                        .product {
                            color: #ffffff;
                            font-size: 1.3rem;
                            font-weight: 900;
                            margin-bottom: 25px;

                            @media screen and (max-width: 380px) {
                                font-size: 1.1rem;
                                margin-bottom: 20px;
                            }
                        }

                        /* Dynamic colours and hover effects for menu nav here */
                        .navbar-selector {
                            position: absolute;
                        }

                        .nav-all-seasons {
                            background: $all-seasons;
                        }

                        &:hover .nav-all-seasons {
                            background: $all-seasons;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        .nav-tall-fescue {
                            background: $tall-fescue;
                        }

                        &:hover .nav-tall-fescue {
                            background: $tall-fescue;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;
                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        .nav-superfine {
                            background: $superfine;
                        }

                        &:hover .nav-superfine {
                            background: $superfine;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        .nav-shady-places {
                            background: $shady-places;
                        }

                        &:hover .nav-shady-places {
                            background: $shady-places;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        .nav-easycare {
                            background: $easycare;
                        }

                        &:hover .nav-easycare {
                            background: $easycare;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        .nav-hot-and-dry {
                            background: $hot-and-dry;
                        }

                        &:hover .nav-hot-and-dry {
                            background: $hot-and-dry;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        .nav-hardwearing {
                            background: $hardwearing;
                        }

                        &:hover .nav-hardwearing {
                            background: $hardwearing;
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        &.active .navbar-selector {
                            width: 150px;
                            height: 80px;
                            position: absolute;
                            bottom: 0;
                            z-index: -111;

                            @media screen and (max-width: 380px) {
                                width: 130px;
                                height: 60px;
                            }
                        }

                        > * {
                            margin: 0;
                        }
                        /* end of menu nav item colours */

                        .image {
                            display: flex;
                            height: 160px;
                            border-radius: 10px;
                            padding-top: 5px;
                            overflow: hidden;
                            justify-content: center;

                            @media screen and (max-width: 481px) {
                                height: 140px;
                            }

                            @media screen and (max-height: 675px) and (max-width: 481px) {
                                height: 100px;
                            }

                            @media screen and (max-width: 380px) {
                                height: 100px;
                            }

                            @media screen and (max-width: 320px) {
                                height: 10px;
                                visibility: hidden;
                            }

                            @media screen and (max-height: 575px) {
                                height: 90px;
                            }

                            @media screen and (max-height: 520px) {
                                height: 70px;
                            }

                            @media screen and (max-height: 480px) {
                                height: 10px;
                                visibility: hidden;
                            }

                            img {
                                filter: drop-shadow(7px -2px 5px #232323);
                            }
                        }
                    }

                    .psv-panel-content img {
                        width: 100%;
                    }
                }
            }
        }
    }

    @keyframes growth {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
}

.psv-navbar {
    @media screen and (max-width: 380px) {
        height: 60px !important;
    }
}
</style>
